<script>
import modalEditPreviewText from "@/components/modals/fixtures/modalEditPreviewText.vue";
import Teams from "@/services/Teams";


export default {
  components: { modalEditPreviewText },
  props: {
    fixture: Object,
    previewLoading: Boolean
  },
  methods: {
    changeTab(tabName) {
      this.$emit("changeTab", tabName);
    },
    generateOnRefresh(tabName){
        this.$emit("onRefresh", tabName);
    },
    refreshPage(tabName) {
      this.$emit("changeTab", tabName);
    },
    callModalEditPreviewText() {
      this.$bvModal.show("edit_preview_text");
    },

   getFixture() {
      Teams.fixtureDetails(this.id).then((response) => {
        this.fixture = response;
      });
      this.title = `${this.fixture.data.match.home_team_name} vs ${this.fixture.data.match.away_team_name}`;
      this.items[1].text = `${this.fixture.data.match.home_team_short} vs ${this.fixture.data.match.away_team_short}`;
   },
  },
  data() {
    return {
      homeTeamForm: this.fixture.data.preview.home_team_form.splice(0, 5),
      awayTeamForm: this.fixture.data.preview.away_team_form
        .splice(0, 5)
        .reverse(),
    };
  },


};
</script>

<template>
  <div>
    <ul class="nav nav-tabs d-flex justify-content-center ">
      <li class="nav-item">
        <a
          class="nav-link active"
          @click.prevent="changeTab('preview')"
          aria-current="page"
          href="#"
          >Preview</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link "
          @click.prevent="changeTab('events')"
          aria-current="page"
          href="#"
          >Events</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" @click.prevent="changeTab('stats')" href="#"
          >Stats</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" @click.prevent="changeTab('lineups')" href="#"
          >Lineups</a
        >
      </li>
    </ul>
    <div class="mt-5 container">
      <div class="row text-center">
        <p class="fs-4 fw-bolder">Venue</p>
        <p class="fs-2">
          {{ fixture.data.match.venue }} <br />
          <span class="fs-5 fw-light">{{ fixture.data.match.venue_city }}</span>
        </p>
      </div>
      <div class="row text-center mt-5">
        <p class="fs-4 fw-bolder">Referee</p>
        <p class="fs-2">
          {{ fixture.data.match.referee_name }} <br />
          <span class="fs-5 fw-light">{{
            fixture.data.match.referee_nation
          }}</span>
        </p>
      </div>
      <div class="row mt-5">
        <p class="text-center fs-4 fw-bolder">Form</p>
        <div class="col">
          <div class="row">
            <div class="col">
              <img
                class="w-50"
                :src="fixture.data.match.home_team_logo"
                alt=""
              />
            </div>
            <div class="col">
              <div class="row fs-5 fw-bold">
                <div>
                  {{ fixture.data.match.home_team_name }}
                </div>
              </div>
              <div class="row">
                <div class="d-flex">
                  <div v-for="res in homeTeamForm" :key="res.versus_team_id">
                    <div
                      v-if="res.win_indicator === 'W'"
                      style="width: 20px;"
                      class="me-2 text-white rounded bg-success text-center user-select-none"
                    >
                      {{ res.win_indicator }}
                    </div>

                    <div
                      v-if="res.win_indicator === 'D'"
                      style="width: 20px;"
                      class="me-2 text-white rounded bg-warning text-center user-select-none"
                    >
                      {{ res.win_indicator }}
                    </div>

                    <div
                      v-if="res.win_indicator === 'L'"
                      style="width: 20px;"
                      class="me-2 text-white rounded bg-danger text-center user-select-none"
                    >
                      {{ res.win_indicator }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col"></div>
        <div class="col d-flex justify-content-end">
          <div class="row">
            <div class="col">
              <div class="row fs-5 fw-bold">
                <div class="text-end">
                  {{ fixture.data.match.away_team_name }}
                </div>
              </div>
              <div class="row">
                <div class="d-flex">
                  <div v-for="res in awayTeamForm" :key="res.versus_team_id">
                    <div
                      v-if="res.win_indicator === 'W'"
                      style="width: 20px;"
                      class="ms-2 text-white rounded bg-success text-center user-select-none"
                    >
                      {{ res.win_indicator }}
                    </div>

                    <div
                      v-if="res.win_indicator === 'D'"
                      style="width: 20px;"
                      class="ms-2 text-white rounded bg-warning text-center user-select-none"
                    >
                      {{ res.win_indicator }}
                    </div>

                    <div
                      v-if="res.win_indicator === 'L'"
                      style="width: 20px;"
                      class="ms-2 text-white rounded bg-danger text-center user-select-none"
                    >
                      {{ res.win_indicator }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <img
                class="w-50"
                :src="fixture.data.match.away_team_logo"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <p class="text-center fs-4 fw-bolder">Preview Text</p>

        <div v-if="previewLoading"  class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>

        <p v-if="fixture.data.preview.preview_text" class="mt-2" style="white-space: pre-wrap;">{{  fixture.data.preview.preview_text }}</p>
        <p v-else class=" text-center fs-2 mt-2">N/A</p>

        <div class="d-flex justify-content-center">
          <b-button class="btn" variant="success" title="Edit Preview Text" style='display:none' @click="callModalEditPreviewText()"> Edit</b-button>
        </div>

      </div>

    </div>

      <!-- MODALS -->
        <modalEditPreviewText :fixture=fixture  @onRefresh="generateOnRefresh()"></modalEditPreviewText>
      <!-- END  MODALS -->
  </div>
</template>







<script>
    export default {
        props: {
            fixture: Object
        },
        methods: {
            changeTab(tabName) {
                this.$emit("changeTab", tabName)
            }
        },
        data(){
            return{
                events:this.fixture.data.events.reverse()
            }
        }
    }
</script>
<template>
    <div>
    <ul class="nav nav-tabs d-flex justify-content-center ">
        <li class="nav-item">
            <a class="nav-link " @click.prevent="changeTab('preview')" aria-current="page" href="#">Preview</a>
        </li>
        <li class="nav-item">
            <a class="nav-link active" @click.prevent="changeTab('events')" aria-current="page" href="#">Events</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" @click.prevent="changeTab('stats')" href="#">Stats</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" @click.prevent="changeTab('lineups')" href="#">Lineups</a>
        </li>
    </ul>
    <div class="mt-5 container">
        <div class="row" v-for="dt in events" :key="dt.id">
            <div v-if="dt.team === 'home'" class="col">
                <div class="container">
                    <p><span class=" me-3"> {{ dt.event_minute }} </span><span class="fw-bold fs-5 me-3">{{ dt.name }}</span><span class="fs-5 me-3">{{ dt.player_short }}</span><span>{{ dt.second_player_short }}</span><span class="fw-bolder ms-3">{{ dt.score }}</span></p>
                </div>
            </div>
            <div class="col">
            </div>
            <div v-if="dt.team === 'away'" class="col" >
                <div class="container">
                    <p class="text-end"><span class="fw-bolder me-3">{{ dt.score }}</span><span class=" me-3">{{ dt.second_player_short }}</span><span class=" fs-5 me-3">{{ dt.player_short }}</span><span class="fw-bolder fs-5 me-3">{{ dt.name }}</span>{{ dt.event_minute }}</p>
                </div>
            </div>
        </div> 
    </div>
    </div>
</template>
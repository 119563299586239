<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Teams from "../../../services/Teams";
import Events from "@/components/tabs/tabFixtureEvents.vue";
import Lineups from "@/components/tabs/tabFixtureLineups.vue";
import Stats from "@/components/tabs/tabFixtureStats.vue";
import Preview from "@/components/tabs/tabFixturePreview.vue";
import TextFormModal from "@/components/modals/fixtures/modalEditPreviewText.vue";
export default {
  page: {
    title: "Current Fixtures",
    meta: [
      {
        name: "fixtures_currents",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stats,
    Lineups,
    Events,
    Preview,
    TextFormModal,
  },

  data() {
    return {
      id: this.$route.params.id,
      openedTab: "preview",
      baseUrl: process.env.VUE_APP_API_BASEURL,
      fixture: null,
      previewLoading: false,
      title: "",
      items: [
        {
          text: "Admin",
        },
        {
          text: "",
          active: true,
        },
      ],
      modalIsOpened: false,
    };
  },
  methods: {
    async getFixture() {
      this.previewLoading = true;

      await Teams.fixtureDetails(this.id).then((response) => {
        this.fixture = response;
      })
      .catch(error => {
            this.error = error.response.data.error ? error.response.data.error : "";
       }).finally(() => {

           this.previewLoading = false;
     });


      this.title = `${this.fixture.data.match.home_team_name} vs ${this.fixture.data.match.away_team_name}`;
      this.items[1].text = `${this.fixture.data.match.home_team_short} vs ${this.fixture.data.match.away_team_short}`;


    },
    changeTab(tabName) {
      this.openedTab = tabName;
    },

    changeModalView() {
      if (this.modalIsOpened === false) {
        this.modalIsOpened = true;
      } else {
        this.modalIsOpened = false;
      }
    },
  },
  mounted() {
    this.getFixture();
  },

};
</script>
<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="container">
                  <div class="row">
                    <div class="d-flex justify-content-between">
                      <div>
                        {{ fixture.data.match.competition.competition_name }}
                        {{ fixture.data.match.competition.season }}
                      </div>
                      <div>Round {{ fixture.data.match.round }}</div>
                    </div>
                  </div>
                  <div
                    v-if="fixture.data.match.score.formatted != ''"
                    class="row justify-content-center"
                  >
                    {{ fixture.data.match.date_formatted }}
                  </div>
                  <div class="row  align-items-center g-2">
                    <div class="col">
                      <div class="row justify-content-center">
                        <img
                          class="w-50"
                          :src="fixture.data.match.home_team_logo"
                          alt=""
                        />
                      </div>
                      <div class="row fs-3 fw-bolder justify-content-center">
                        {{ fixture.data.match.home_team_name }}
                      </div>
                    </div>
                    <div class="col">
                      <div v-if="fixture.data.match.score.formatted != ''">
                        <div class="row fs-1  justify-content-center">
                          {{ fixture.data.match.score.formatted }}
                        </div>
                      </div>
                      <div
                        class="row fs-5  justify-content-center align-items-center"
                        v-else
                      >
                        {{ fixture.data.match.date_formatted }}
                      </div>
                      <div
                        class="row"
                        v-if="fixture.data.match.progress.is_FT === 'N'"
                      ></div>
                    </div>
                    <div class="col">
                      <div class="row justify-content-center">
                        <img
                          class="w-50"
                          :src="fixture.data.match.away_team_logo"
                          alt=""
                        />
                      </div>
                      <div class="row fs-3 fw-bolder justify-content-center">
                        {{ fixture.data.match.away_team_name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hr m-5">
                  <div v-if="openedTab === 'events'">
                    <Events :fixture="fixture" @changeTab="changeTab" />
                  </div>
                  <div v-if="openedTab === 'stats'">
                    <Stats :stats="fixture.data.stats" @changeTab="changeTab" />
                  </div>
                  <div v-if="openedTab === 'lineups'">
                    <Lineups
                      :lineup="fixture.data.lineups"
                      @changeTab="changeTab"
                    />
                  </div>
                  <div v-if="openedTab === 'preview'">
                    <Preview
                      :fixture="fixture"
                      :previewLoading="previewLoading"
                      @changeTab="changeTab"
                      @openModal="changeModalView"
                      @onRefresh="getFixture()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>



    <TextFormModal
      :fixture="fixture"
      v-if="modalIsOpened === true"
      @closeModal="changeModalView"
    />
    <div class="alert alert-container alert-success d-flex align-items-center" role="alert">
      <svg
        class="bi flex-shrink-0 me-2"
        width="24"
        height="24"
        role="img"
        aria-label="Success:"
      >
        <use xlink:href="#check-circle-fill" />
      </svg>
      <div>
        An example success alert with an icon
      </div>
    </div>
  </div>
</template>
<style>
    .alert-container {
        left:100;
        position:fixed;
        z-index: 9999;
    }
</style>
<script>
export default {
        props: {
            stats:Object
        },
        methods: {
            changeTab(tabName) {
                this.$emit("changeTab", tabName)
            }
        }
    }
</script>
<template>
    <div>
    <ul class="nav nav-tabs d-flex justify-content-center ">
        <li class="nav-item">
            <a class="nav-link " @click.prevent="changeTab('preview')" aria-current="page" href="#">Preview</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " @click.prevent="changeTab('events')" aria-current="page" href="#">Events</a>
        </li>
        <li class="nav-item">
            <a class="nav-link active" @click.prevent="changeTab('stats')" href="#">Stats</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " @click.prevent="changeTab('lineups')" href="#">Lineups</a>
        </li>
    </ul>
    <div class="mt-5">
        <div class="row mt-5" v-for="st in stats" :key="st.stat_code">
            <div class="row ">
                <p class="fs-5 fw-bolder text-center">{{ st.stat_desc }}</p>
            </div>
            <div class="row">
                <div class="col">
                <div class="row">
                    {{ st.home_points }}
                </div>
                <div class="row">
                  <div class="progress justify-content-end">
                     <div v-if="st.home_points<st.away_points && st.total_points != 0" class="progress-bar bg-warning" role="progressbar" :style="{width : st.home_points/st.total_points*100 + '%'}" :aria-valuenow=st.home_points :aria-valuemax=st.total_points aria-valuemin="0"  ></div>
                     <div v-else-if="st.home_points>=st.away_points && st.total_points != 0" class="progress-bar bg-success" role="progressbar" :style="{width : st.home_points/st.total_points*100 + '%'}" :aria-valuenow=st.home_points :aria-valuemax=st.total_points aria-valuemin="0"  ></div>
                 </div>  
                </div>
                  
                
                </div>
                 
                <div class="col">
                <div class="row justify-content-end">
                    {{ st.away_points }}
                </div>
                <div class="row">
                    <div class="progress">
                     <div v-if="st.home_points>st.away_points && st.total_points != 0" class="progress-bar bg-warning" role="progressbar" :style="{width : st.away_points/st.total_points*100 + '%'}" :aria-valuenow=st.away_points :aria-valuemax=st.total_points aria-valuemin="0"  ></div>
                     <div v-else-if="st.home_points<=st.away_points && st.total_points != 0" class="progress-bar bg-success" role="progressbar" :style="{width : st.away_points/st.total_points*100 + '%'}" :aria-valuenow=st.away_points :aria-valuemax=st.total_points aria-valuemin="0"  ></div>
                     
                    </div>
                </div>

                </div>
            </div>

        </div>
        
    </div>

    </div>

</template>

<script>
export default {
        props:{
            lineup: Object
        },
        methods: {
            changeTab(tabName) {
                this.$emit("changeTab", tabName)
            }
        }
    }
</script>
<template>
    <div>

    <ul class="nav nav-tabs d-flex justify-content-center ">
        <li class="nav-item">
            <a class="nav-link " @click.prevent="changeTab('preview')" aria-current="page" href="#">Preview</a>
        </li>
        <li class="nav-item">
            <a class="nav-link " @click.prevent="changeTab('events')" aria-current="page" href="#">Events</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" @click.prevent="changeTab('stats')" href="#">Stats</a>
        </li>
        <li class="nav-item">
            <a class="nav-link active" @click.prevent="changeTab('lineups')" href="#">Lineups</a>
        </li>
    </ul>
    <div class="mt-5 container">
        <div class="row">
            
            
        </div>
        <div class="fs-4 fw-bolder row d-flex justify-content-center">
            Starters
        </div>
        
        <div class="row" >
            <div class="col" >
                <div class="row fs-4 fw-bolder">
                    <p class="text-center">{{ lineup.away_team_formation }}</p>
                </div>
                <div class="row" v-for="ln in lineup.home_team_starters" :key="ln.player_id">
                    <p class="text-center">{{ ln.shirt_number }} <span class=" fw-bolder ms-3">{{ ln.player_name }}</span><span class="fw-bold ms-3" v-if="ln.player_captain===1">C</span></p>
                </div>
            </div>
            <div class="col">
            </div>
            <div class="col" >
                <div class="col fs-4 fw-bolder">
                    <p class="text-center">{{ lineup.away_team_formation }}</p>
                </div>
                <div class="row" v-for="ln in lineup.away_team_starters" :key="ln.player_id">
                    <p class="text-center"> <span class="fw-bold me-3" v-if="ln.player_captain===1">C</span><span class=" fw-bolder me-3">{{ ln.player_name }}</span>{{ ln.shirt_number }} </p>
                </div>
            </div>
        </div>
        <hr/>
        <div class="fs-4 fw-bolder row justify-content-center">
            Substitutes
        </div>
        <div class="row" >
            <div class="col " >
                <div class="row" v-for="ln in lineup.home_team_subs" :key="ln.player_id">
                    <p class="text-center">{{ ln.shirt_number }} <span class=" fw-bolder ms-3">{{ ln.player_name }}</span><span class="fw-bold ms-3" v-if="ln.player_captain===1">C</span></p>
                </div>
            </div>
            <div class="col">
            </div>
            <div class="col " >
                <div class="row" v-for="ln in lineup.away_team_subs" :key="ln.player_id">
                    <p class="text-center"> <span class="fw-bold me-3" v-if="ln.player_captain===1">C</span><span class=" fw-bolder me-3">{{ ln.player_name }}</span>{{ ln.shirt_number }} </p>
                </div>
            </div>
        </div>
        <hr/>
        <div class="fs-4 fw-bolder row d-flex justify-content-center">
            Managers
        </div>
        <div class="row">
            <div class="col">
                <p class="fs-3 ">{{ lineup.home_team_manager.player_name }}</p>
            </div>
            <div class="col"></div>
            <div class="col d-flex justify-content-end">
                <p class="fs-3">{{ lineup.away_team_manager.player_name }}</p>
            </div>
        </div>
    </div>
    </div>
</template>
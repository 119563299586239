<script>
import Teams from '@/services/Teams';
import Swal from "sweetalert2";

export default {
  props: {
    fixture: Object,
  },

   data() {
      return {
          csrf_token: localStorage.getItem('csrf_token'),
          submitted: false,
          showModal: false,
          tryingToEdit: false,
          preview_text:this.fixture.data.preview.preview_text,
          id: this.$route.params.id,
      };
    },

  methods: {
     closeModal() {
        this.showModal = false;
    },

    refreshData() {
       this.$emit('onRefresh') //event from parent
    },

    changePreviewText() {
        this.tryingToEdit = true;
        Teams.editMatchPreviewText(this.id, {
            csrf_token: this.csrf_token,
            text: this.preview_text
        })
        .then((response) => {
            this.tryingToSubmit = false;
            this.tryingToEdit = false;
            const res = response.data.message ? response.data.message : false;
            const error = response.data.error ? response.data.error : 'Failed';
            if(res){
                 this.successmsg("Match preview text successfully changed!");
                 this.refreshData();
                 this.closeModal();
            }else{
                 this.failedmsg("Failed to change match preview text!")
            }
        })
        .catch(error => {
            this.tryingToSubmit = false;
            this.tryingToEdit = false;
            Swal.fire("Fail!", error, "warning");
        });
      
        //this.text = JSON.stringify(this.match.data.match)
    }
  },

};
</script>


<template>
    <b-modal v-model="showModal" id="edit_preview_text"  size="xl" title="Edit Preview Text" title-class="font-18" centered>
        <form @submit.prevent="changePreviewText">

            <b-form-group label="" label-for="formrow-amount-input" class="mb-3">
                <textarea v-model="preview_text" class="w-100 modal-form" placeholder="Add preview text" rows="10"></textarea>

            </b-form-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="changePreviewText" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit
            </b-button>
        </template>
    </b-modal>
</template>


